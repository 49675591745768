function initSimpleToggler() {
  const toggleContainer = document.querySelector('[data-simple-toggle-container]');
  if (toggleContainer) {
    const ulContainers = toggleContainer.querySelectorAll('[data-ul-container]');
    if (toggleContainer) {
      ulContainers.forEach((container) => {
        const toggleLinks = container.querySelectorAll('[data-simple-toggle-link]');
        toggleLinks.forEach((link) => {
          link.addEventListener('click', () => {
            const linkParent = link.closest('[data-simple-toggle-block]');
            const choosElement = linkParent.querySelector('[data-simple-toggle-content]');
            const openLink = container.querySelectorAll('[data-simple-toggle-link]._show, [data-simple-toggle-content]._show');
            if (openLink) {
              openLink.forEach((openWindow) => {
                openWindow.classList.remove('_show');
              });
            }
            link.classList.add('_show');
            choosElement.classList.add('_show');
          });
        });
      });
    }
  }
}


document.addEventListener('DOMContentLoaded', initSimpleToggler);