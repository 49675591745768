import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if (link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
        if (link.dataset.goalFacebook && link.dataset.goalAction) {
          window.fbq('track', link.dataset.goalFacebook);
        }
      }
    },
  });
  return false;
});

// дальше будет конкретный понос, чуваки. им надо на каждый пук отдельну форму сраного битрикса
// так что закрываем глаза, открывает рот, кайфуем
// я пока обработал 3, а дальше хуй положил плотный такой
new LiveEvent('click', '[data-modal-offer]', function openModal(e) {
  e.preventDefault();

  let modal = new Modal(this, {
    closerText: '',

    onAfterStart: function() {
      const container = document.querySelector('.modal__container');
      container.classList.add('modal-inner-content');

      const script = document.createElement('script');
      script.setAttribute('data-b24-form', 'inline/46/itshhe');
      script.setAttribute('data-skip-moving', 'true');
      script.text = `(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b12009524/crm/form/loader_46.js');`;

      container.appendChild(script);
    },

    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if (link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
        if (link.dataset.goalFacebook && link.dataset.goalAction) {
          window.fbq('track', link.dataset.goalFacebook);
        }
      }
    },
  });

  return false;
});

new LiveEvent('click', '[data-image-form]', function openModal(e) {
  e.preventDefault();

  let modal = new Modal(this, {
    closerText: '',

    onAfterStart: function() {
      const container = document.querySelector('.modal__container');
      console.log(container);
      container.classList.add('modal-inner-content');

      const script = document.createElement('script');
      script.setAttribute('data-b24-form', 'inline/54/vkp86u');
      script.setAttribute('data-skip-moving', 'true');
      script.text = `(function(w,d,u){var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);})(window,document,'https://cdn-ru.bitrix24.ru/b12009524/crm/form/loader_54.js');`;

      container.appendChild(script);
    },

    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if (link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
        if (link.dataset.goalFacebook && link.dataset.goalAction) {
          window.fbq('track', link.dataset.goalFacebook);
        }
      }
    },
  });

  return false;
});