import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

function bindArrows(left, right, slider) {
  const leftArrow = document.querySelector(left);
  const rightArrow = document.querySelector(right);
  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      slider.goTo('prev');
    });
  }

  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      slider.goTo('next');
    });
  }
}

if (document.querySelector('[data-for-which-spheres-slider]')) {
  const slider = tns({
    container: '[data-for-which-spheres-slider]',
    slideBy: 3,
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: false,
    rewind: false,
    responsive: {
      320: {
        items: 1.3,
        loop: false,
        rewind: false,
        gutter: 14,
        nav: true,
      },
      768: {
        items: 2.3,
        gutter: 24,
        nav: false,
        loop: false,
        rewind: false,
      },
      1280: {
        items: 3,
        disabled: true,
        gutter: 20,
        nav: false,
      },
    },
  });

  bindArrows('[data-for-which-spheres-left]', '[data-for-which-spheres-right]', slider);
}

if (document.querySelector('[data-dev-slider]')) {
  const slider = tns({
    container: '[data-dev-slider]',
    slideBy: 3,
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    responsive: {
      325: {
        items: 1.5,
        disabled: false,
        gutter: 16,
        nav: false,
      },
      768: {
        items: 1.5,
        disabled: false,
        gutter: 16,
        nav: false,
      },
      1280: {
        items: 3.5,
        disabled: false,
        gutter: 43,
      },
    },
  });

  bindArrows('[data-for-which-spheres-left]', '[data-for-which-spheres-right]', slider);
}

if (document.querySelector('[data-review-slider]')) {
  const slider = tns({
    container: '[data-review-slider]',
    slideBy: 3,
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    responsive: {
      325: {
        items: 1.5,
        disabled: false,
        gutter: 16,
        nav: false,
      },
      768: {
        items: 1.5,
        disabled: false,
        gutter: 16,
        nav: false,
      },
      1280: {
        items: 3.5,
        disabled: false,
        gutter: 43,
      },
    },
  });

  bindArrows('[data-for-which-spheres-left]', '[data-for-which-spheres-right]', slider);
}

if (document.querySelector('[data-vac-slider]')) {
  const slider = tns({
    container: '[data-vac-slider]',
    slideBy: 3,
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    responsive: {
      325: {
        items: 1.5,
        disabled: false,
        gutter: 23,
        fixedWidth: 253,
        nav: false,
      },
      768: {
        items: 1.5,
        disabled: false,
        gutter: 23,
        fixedWidth: 253,
        nav: false,
      },
      1280: {
        items: 3.5,
        disabled: false,
        gutter: 43,
        fixedWidth: 310,
      },
    },
  });

  bindArrows('[data-for-which-spheres-left]', '[data-vac-right]', slider);
}

if (document.querySelector('[data-offer-slider]')) {
  const slider = tns({
    container: '[data-offer-slider]',
    slideBy: 1,
    mode: 'gallery',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    animateNormal: 'normal',
    speed: 700,
    nav: true,
    navPosition: 'bottom',
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
  });

  bindArrows('[data-for-which-spheres-left]', '[data-arrow-right]', slider);
}

const sliders = document.querySelectorAll('[data-service-slider]');
sliders.forEach((item) => {
  if (item) {
    const service = tns({
      container: item,
      slideBy: 1,
      speed: 700,
      controls: false,
      loop: false,
      rewind: true,
      swipeAngle: false,
      mouseDrag: true,
      responsive: {
        325: {
          items: 1.5,
          disabled: false,
          gutter: 23,
          nav: false,
        },
        768: {
          items: 1.5,
          disabled: false,
          gutter: 23,
          nav: false,
        },
        1280: {
          items: 3,
          disabled: false,
          gutter: 43,
        },
      },
    });

    bindArrows('[data-arrow-left]', '[data-arrow-right-right]', service);
  }
});

if (document.querySelector('[data-certificates-slider]')) {
  const slider = tns({
    container: '[data-certificates-slider]',
    slideBy: 3,
    speed: 700,
    controls: false,
    swipeAngle: false,
    mouseDrag: true,
    responsive: {
      325: {
        items: 1.5,
        disabled: false,
        gutter: 36,
        nav: false,
      },
      768: {
        items: 2.5,
        disabled: false,
        gutter: 36,
        nav: false,
      },
      1280: {
        items: 4.5,
        disabled: false,
        gutter: 37,
      },
    },
  });
}

if (document.querySelector('[data-about-certificates-slider]')) {
  const slider = tns({
    container: '[data-about-certificates-slider]',
    items: 5,
    slideBy: 1,
    center: false,
    speed: 1500,
    loop: true,
    controls: false,
    mouseDrag: true,
    responsive: {
      325: {
        gutter: 10,
      },
      768: {
        gutter: 10,
      },
      1280: {
        gutter: 10,
      },
    },
  });
}

if (document.querySelector('[data-out-slider]')) {
  const slider = tns({
    container: '[data-out-slider]',
    slideBy: 1,
    speed: 700,
    centerMode: true,
    controls: false,
    loop: true,
    swipeAngle: false,
    mouseDrag: true,
    responsive: {
      325: {
        items: 1,
        disabled: false,
        nav: true,
        gutter: 50,
      },
      768: {
        items: 1,
        disabled: false,
        nav: false,
        gutter: 50,
      },
      1280: {
        items: 1,
        disabled: false,
        gutter: 50,
      },
    },
  });

  bindArrows('[data-arrow-left]', '[data-arrow-right]', slider);
}