require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/simple-class-toggler');
require('./sliders/sliders');
require('./common/range-calculate');
require('./common/range-calculate-rent');

require('fslightbox');

require('./field/phone.js');
require('./common/floating-field');
require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('./hamburger');
require('./field-form-filler');
require('./addActive');
require('./accordion/initializator');
require('../vue/app.js');