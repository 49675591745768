import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

document.addEventListener('DOMContentLoaded', () => {
  const machinesInput = document.querySelector('[name="RentForm[user_count][0]"]');
  const serversInput = document.querySelector('[name="RentForm[ssd_capacity][0]"]');
  const officeMachinesInput = document.querySelector('[name="RentForm[hdd_capacity][0]"]');
  const officesInput = document.querySelector('[name="RentForm[core_count][0]"]');
  const ramCapacity = document.querySelector('[name="RentForm[ram_capacity][0]"]');

  new LiveEvent('click', '[data-modal-calc-rent]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
      closerText: '',
      onFormSuccess: function () {
        if (link.dataset.goal) {
          let action = 'click';
          if (link.dataset.goalAction) {
            action = link.dataset.goalAction;
          }
          window.goal(link.dataset.goal, action);
          if (link.dataset.goalFacebook && link.dataset.goalAction) {
            window.fbq('track', link.dataset.goalFacebook);
          }
        }
      },
      onAfterOpen() {
        const modalForm = document.querySelector('.bitrix-modal__form');
        const modalMachinesInput = modalForm.querySelector('[name="RentForm[user_count][0]"]');
        const modalServersInput = modalForm.querySelector('[name="RentForm[ssd_capacity][0]"]');
        const modalOfficeMachinesInput = modalForm.querySelector('[name="RentForm[hdd_capacity][0]"]');
        const modalOfficesInput = modalForm.querySelector('[name="RentForm[core_count][0]"]');
        const modalRamCapacity = modalForm.querySelector('[name="RentForm[ram_capacity][0]"]');

        const excludedFields = ['total_params', 'user_count', 'ssd_capacity', 'hdd_capacity', 'core_count', 'ram_capacity'];
        excludedFields.forEach((fieldName) => {
          const field = modalForm.querySelector(`.${fieldName}`);
          if (field) {
            field.style.setProperty('display', 'none');
          }
        });

        if (modalMachinesInput) {
          modalMachinesInput.value = machinesInput.value;
          const machinesShow = modalForm.querySelector('[data-range-single-show]');
          if (machinesShow) machinesShow.textContent = machinesInput.value;
        }

        if (modalServersInput) {
          modalServersInput.value = serversInput.value;
        }

        if (modalOfficeMachinesInput) {
          modalOfficeMachinesInput.value = officeMachinesInput.value;
        }

        if (modalOfficesInput) {
          modalOfficesInput.value = officesInput.value;
        }

        if (modalRamCapacity) {
          modalRamCapacity.value = ramCapacity.value;
        }
      },
    });
    return false;
  });
});