import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

document.addEventListener('DOMContentLoaded', () => {
  const machinesInput = document.querySelector('[name="OutsourceForm[machines_count][0]"]');
  const serversInput = document.querySelector('[name="OutsourceForm[server_count][0]"]');
  const officeMachinesInput = document.querySelector('[name="OutsourceForm[office_machines_count][0]"]');
  const officesInput = document.querySelector('[name="OutsourceForm[offices_count][0]"]');
  const totalElement = document.querySelector('.constructor__total');

  function calculateTotal() {
    let total = 15000;

    total += (parseInt(machinesInput.value, 10) - 10) * 800;
    total += (parseInt(serversInput.value, 10) - 1) * 4000;
    total += (parseInt(officeMachinesInput.value, 10) - 2) * 500;
    total += (parseInt(officesInput.value, 10) - 1) * 2000;
    totalElement.textContent = `от ${total} ₽/мес`;
  }

  if (machinesInput && serversInput && officeMachinesInput && officesInput) {
    machinesInput.addEventListener('change', calculateTotal);
    serversInput.addEventListener('change', calculateTotal);
    officeMachinesInput.addEventListener('change', calculateTotal);
    officesInput.addEventListener('change', calculateTotal);

    calculateTotal();
  }

  new LiveEvent('click', '[data-modal-out]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
      closerText: '',
      onFormSuccess: function () {
        if (link.dataset.goal) {
          let action = 'click';
          if (link.dataset.goalAction) {
            action = link.dataset.goalAction;
          }
          window.goal(link.dataset.goal, action);
          if (link.dataset.goalFacebook && link.dataset.goalAction) {
            window.fbq('track', link.dataset.goalFacebook);
          }
        }
      },
      onAfterOpen() {
        const modalForm = document.querySelector('.bitrix-modal__form');
        const modalMachinesInput = modalForm.querySelector('[name="OutsourceForm[machines_count][0]"]');
        const modalServersInput = modalForm.querySelector('[name="OutsourceForm[server_count][0]"]');
        const modalOfficeMachinesInput = modalForm.querySelector('[name="OutsourceForm[office_machines_count][0]"]');
        const modalOfficesInput = modalForm.querySelector('[name="OutsourceForm[offices_count][0]"]');

        const excludedFields = ['total_params', 'machines_count', 'office_machines_count', 'offices_count', 'server_count'];
        excludedFields.forEach((fieldName) => {
          const field = modalForm.querySelector(`.${fieldName}`);
          if (field) {
            field.style.setProperty('display', 'none');
          }
        });

        if (modalMachinesInput) {
          modalMachinesInput.value = machinesInput.value;
          const machinesShow = modalForm.querySelector('[data-range-single-show]');
          if (machinesShow) machinesShow.textContent = machinesInput.value;
        }

        if (modalServersInput) {
          modalServersInput.value = serversInput.value;
        }

        if (modalOfficeMachinesInput) {
          modalOfficeMachinesInput.value = officeMachinesInput.value;
        }

        if (modalOfficesInput) {
          modalOfficesInput.value = officesInput.value;
        }
      },
    });
    return false;
  });
});