<template>

</template>

<script>
  export default {
    name: 'App.vue'
  };
</script>

<style>
    .app {
        background: red;
    }
</style>