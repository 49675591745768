function initActive() {
  const items = document.querySelectorAll('[data-need-active]');
  const itemsParent = document.querySelectorAll('[data-parent-need-active]');
  items.forEach((item) => {
    if (item) {
      item.addEventListener('click', (e) => {
        const ulActive = document.querySelector('[data-ul-active]');
        if (!item.classList.contains('_active')) {
          item.classList.add('_active');
          ulActive.classList.add('_active');
        } else {
          item.classList.remove('_active');
          ulActive.classList.remove('_active');
        }
      });
    }
  });
  itemsParent.forEach((itemP) => {
    if (itemP) {
      itemP.addEventListener('click', (e) => {
        const lists = itemP.querySelector('[data-test]');
        if (!lists.classList.contains('_active')) {
          lists.classList.add('_active');
          itemP.classList.add('_active');
        } else {
          lists.classList.remove('_active');
          itemP.classList.remove('_active');
        }
      });
    }
  });
}

document.addEventListener('DOMContentLoaded', initActive);